.change-state-section .mainButtons button {
  margin-top: 6%;
  padding: 12px 24px;
  margin-right: 5px;
  background-color: #1976d2;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.change-state-section button:active {
  background-color: #0d47a1;
}

.change-state-section .change > * {
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 20px;
  width: 20%;
}

.submitBtn {
  color: #fff;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Set a high z-index value */
}

.popup-content {
  width: 700px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  resize: none;
}

.popup-content button {
  margin-right: 10px;
}

.change-state-section .changeStage {
  margin-top: 4rem;
  width: 35vw;
}
.changeStage {
  width: 35vw;
}
.change-state-section .changeStage .change {
  display: flex;
  gap: 30px;
}

.bottom-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

#dd1 {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  height: 30px;
}
textarea {
  resize: none;
  font-weight: 500;
  font-size: 14px;
}
label {
  font-weight: 700;
  font-size: 20px;
}

.change-state-section .change2 > * {
  margin-top: 3%;
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 20px;
  width: 20%;
}

.change-state-section .change2 .emails {
  margin-top: 5%;
}

.change-state-section .changeStage2 {
  margin-top: 4rem;
}

.tables-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stage-table {
  width: 45%;
  padding-bottom: 12px;
}

.stage-table th {
  text-align: left;
}

.stage-table td {
  text-transform: capitalize;
}
