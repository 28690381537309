.pageContainer {
  margin: 100px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  font-size: 2.5rem;
  margin: 0;
}

button {
  padding: 0.9rem 2rem;
  background: #2f7de5;
  outline: 0;
  border: 0;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border-radius: 0.5rem;
}

.header button:hover {
  opacity: 0.9;
  transform: scale(0.9);
}

.addedJobs h2 {
  margin-bottom: 2rem;
}

.addedJobsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
}

@media screen and (max-width: 1200px) {
  .addedJobsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .addedJobsGrid {
    grid-template-columns: 1fr;
  }
}

.jobCard {
  border-radius: 1rem;
  background: rgba(230, 230, 230, 0.25);
  padding: 1.5rem 2rem;
}

.jobCard h1,
.jobCard h2,
.jobCard h3,
.jobCard h4 {
  padding: 0;
  margin: 0;
}

.jobCard h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.jobCard h3 span {
  font-size: 0.5rem;
  margin-top: 0.2rem;
  margin-right: 0.75rem;
  color: #134b94;
}

.jobCard h4 {
  font-size: 1.02rem;
  font-weight: 600;
  margin: 0.4rem 0;
}

.jobCard h4 span {
  opacity: 0.9;
  font-style: italic;
}

.jobCard button {
  background: #134b94;
  color: white;
  border: 0;
  outline: 0;
  padding: 0.5rem 2rem;
  margin-top: 0.75rem;
  font-weight: 600;
  font-size: 0.85rem;
  letter-spacing: 0.7px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}

.jobCard button:hover {
  opacity: 0.8;
}
