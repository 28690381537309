.placement-container {
  margin: 100px 0;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
}

.form-fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1.4rem;
  margin-bottom: 2.5rem;
}

.form-fields-container > div {
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 0.6rem;
}

.btn {
  padding: 0.75rem 4rem !important;
}

/* Mock Score Form Styles */
.mock-score-block {
  margin: 2rem 0;
}

.mock-score-block:not(:first-of-type) {
  margin-top: 4.4rem;
  position: relative;
}

.mock-score-block:not(:first-of-type)::before {
  content: "AND";
  position: absolute;
  top: -2.2rem;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: 700;
  color: #042953;
  text-decoration: underline;
}

.individual-mock-score {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 3rem 3rem;
  gap: 2rem;
  margin: 1rem 0 1rem 0;
  padding-left: 3rem;
  position: relative;
}

.individual-mock-score::before {
  content: "";
  position: absolute;
  left: 1.5rem;
  background: #1565c0;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logical-operator {
  background: #1565c030 !important;
  color: #1565c0 !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  transition: all 0.15s ease-in-out !important;
  min-height: 46px;
}

.logical-operator:hover,
.delete-button:hover {
  transform: scale(0.8);
}

.delete-button {
  background-color: rgba(255, 0, 0, 0.08) !important;
  color: red !important;
  transition: all 0.15s ease-in-out !important;
}

/* DISABLE AND OR BUTTONS */
.mock-score-block:not(:last-of-type) .logical-operator.or,
.individual-mock-score:not(:last-of-type) .logical-operator.or {
  background: rgb(188, 188, 188) !important;
  color: white !important;
  pointer-events: none !important;
  font-weight: 500 !important;
}

.mock-score-block:not(:last-of-type) .logical-operator.and {
  display: none !important;
}

.eligibility-form-buttons {
  display: flex;
  gap: 1rem;
}
