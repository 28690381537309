.placement-container {
  margin: 100px 0;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
}

.form-fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1.4rem;
  margin-bottom: 2.5rem;
}

.form-fields-container > div {
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 0.6rem;
}

.btn {
  padding: 0.75rem 4rem !important;
}

.add-button {
  background-color: rgba(21, 223, 6, 0.08) !important;
  color: green !important;
  transition: all 0.15s ease-in-out !important;
}

.delete-button {
  background-color: rgba(255, 0, 0, 0.08) !important;
  color: red !important;
  transition: all 0.15s ease-in-out !important;
}

.resource-row {
  display: grid;
  grid-template-columns: 1fr 4rem 4rem;
  column-gap: 1rem;
  margin-bottom: 0.6rem;
}

/* Rich Text Editor */
.ql-toolbar.ql-snow {
  padding: 14px 8px !important;
}

.rich-text-area .ql-editor {
  font-size: 1.1rem;
}

/* Custom Questions */

.custom-questions-container {
  margin-left: 2.4rem;
}

.individual-custom-question {
  position: relative;
  margin-bottom: 1.2rem;
}

.individual-custom-question::before {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #021a35bd;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: -1.5rem;
  top: 1.95rem;
  transform: translateX(-50%);
}

.individual-custom-question > div {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.individual-custom-question > div:not(:last-of-type) {
  align-items: baseline;
}

.individual-custom-question h4 {
  font-weight: 600;
  color: rgb(62, 62, 62);
  font-size: 1.05rem;
}

.custom-question-type {
  display: grid;
  column-gap: 2.5rem;
}

.option-row {
  display: grid;
  grid-template-columns: 1fr 4rem 4rem;
  column-gap: 1rem;
  margin: 0.75rem 0 0 0;
}

.add-custom-question {
  margin-top: 1rem;
}

.add-custom-question-button {
  background: #021a3517 !important;
  padding: 0.6rem 2rem !important;
  transition: all 0.15s ease-in-out !important;
}

.add-custom-question-button svg {
  margin: 0 0.15rem;
}

.add-button:hover,
.delete-button:hover,
.add-custom-question-button:hover {
  transform: scale(0.85);
}

.individual-custom-question:not(:last-of-type) .add-custom-question {
  display: none !important;
}
