@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv,
.e-grid .e-gridheader .e-stackedheadercelldiv {
  padding: 0px;
}
.e-grid .e-gridheader .e-sortfilter .e-rightalign .e-headercelldiv,
.e-grid .e-gridheader .e-rightalign .e-stackedheadercelldiv {
  padding: 0px;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  padding: 0 8px 0;
}

.e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
  margin: 0px -7px -7px -7px;
}
#js-licensing {
  display: none !important;
}
