@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap");
.nav-div {
  display: flex;
  flex-direction: row;
  color: white;
  text-decoration: none;
  justify-content: space-between;
  height: 40px;
  padding-top: 5px;
  padding-inline: 5px;
  position: fixed;
  width: 100vw;
  z-index: 100000;
  top: 0;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(47, 128, 237, 1) 0%,
    rgba(44, 48, 49, 1) 100%
  );
}
.nav-links > div {
  display: flex;
  padding-right: 20px;
}
.nav-left {
  margin: 0px 0px 5px 5px;
  padding-inline: 5px;
  background-color: aliceblue;
  border-radius: 8px;
  color: #262626;
  align-items: center;
  display: flex;
  font-size: 24px;
  letter-spacing: -0.05em;
  font-family: "Bai Jamjuree", sans-serif !important;
  font-weight: 500;
}
/* .nav-links {
  padding-right: 2vw;
} */

.nav-links > a:hover {
  color: white;
  background-color: #1976d2;
}

.views-dropdown {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;
  padding: 5px;
  background-color: rgb(116, 181, 237);
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}
.MobileNav {
  display: none !important;
}
@media (max-width: 800px) {
  .MobileNav {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-right: 20px;
  }
  .MobileNav > div {
    background-color: #1976d2;
    border-radius: 8px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .nav-links {
    display: none !important;
  }
}
