.company-container {
  margin: 100px 0;
}

.company-heading {
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
}

.company-btn {
  padding: 0.75rem 4rem !important;
}

.form-subheading {
  padding: 0;
  margin: 0;
}
